import { colors, fonts } from '@drivecapital/design-system';
import { Temporal } from '@js-temporal/polyfill';
import React from 'react';
import styled from 'styled-components';

function parseEventDate(date: string): Temporal.Instant {
	try {
		return Temporal.Instant.from(date);
	} catch {
		const plainDate = Temporal.PlainDate.from(date);

		return Temporal.ZonedDateTime.from({
			timeZone: 'UTC',
			year: plainDate.year,
			month: plainDate.month,
			day: plainDate.day,
			hour: 0,
			minute: 0,
			second: 0,
		}).toInstant();
	}
}

export function formatDate(date: string): string {
	return parseEventDate(date)
		.toZonedDateTimeISO('UTC')
		.toPlainDate()
		.toLocaleString('en-Us', {
			day: '2-digit',
			month: '2-digit',
			year: '2-digit',
		});
}

const Header = styled.div`
	${fonts.paragraph.paragraph};
	align-items: center;
	color: ${colors.text.primary};
	display: flex;
	gap: 4px;
	margin-bottom: 6px;
`;
const Title = styled.span``;
const Date = styled.span`
	flex: 1;
	text-align: right;
`;

interface Props {
	readonly chip: React.ReactNode;
	readonly className?: string;
	readonly date: string;
}

export default function LatestInteractionHeader({
	chip,
	className,
	date,
}: Props) {
	return (
		<Header className={className}>
			<Title>Your Last Interaction</Title>
			{chip}
			<Date>{formatDate(date)}</Date>
		</Header>
	);
}
