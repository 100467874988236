import { colors, fonts } from '@drivecapital/design-system';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import type { TravelCompanyRecommendation } from '../types';

import { formatDate } from './latest-interaction-header';

const Body = styled.div`
	${fonts.paragraph.strong};
	align-items: center;
	color: ${colors.text.primary};
	display: flex;
	gap: 4px;
	overflow: hidden;

	span {
		white-space: nowrap;
	}
`;
const NameAndLogo = styled(Link)`
	${fonts.paragraph.paragraph};
	align-items: center;
	color: ${colors.text.interactive};
	display: flex;
	gap: 4px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	&:hover {
		color: ${colors.text.interactive};
	}
`;

const Logo = styled.img`
	border-radius: 50%;
	border: 1px solid ${colors.border.subtle};
	grid-area: logo;
	height: 24px;
	object-fit: cover;
	width: 24px;
`;

const MeetingDate = styled.span`
	${fonts.paragraph.paragraph};
	color: ${colors.text.secondary};
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

interface Props {
	readonly className?: string;
	readonly similarCompany: NonNullable<
		TravelCompanyRecommendation['similar_company']
	>;
}

export default function SimilarCompany({ className, similarCompany }: Props) {
	return (
		<Body className={className}>
			<span>Similar to </span>
			<NameAndLogo
				to={`/companies/${similarCompany.id}`}
				title={similarCompany.name}
			>
				<Logo src={similarCompany.logo_url} alt="" />
				<span>{similarCompany.name}</span>
			</NameAndLogo>
			<MeetingDate
				title={`Meeting on ${formatDate(similarCompany.upcoming_event.date)}`}
			>
				(meeting {formatDate(similarCompany.upcoming_event.date)})
			</MeetingDate>
		</Body>
	);
}
